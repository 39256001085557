<template>
  <div class="main">
    <heand :tagnum="tagnum"></heand>
    <div class="portrait_ban">
      <dl>
        <dt>
          <el-input v-model="company" placeholder="请输入企业名称"></el-input>
          <el-button type="primary" @click="clicksearch360">搜索</el-button>
        </dt>
        <dd>
          历史搜索：
          <span
            v-for="(item, index) in historySearch"
            :key="index"
            @click="clickhistory(item)"
            >{{ item }}</span
          >
        </dd>
      </dl>
    </div>

    <div class="chuangx_fffbox">
      <ul class="ipt">
        <li>
          企业类型：
          <el-select
            class="iipp"
            v-model="firmtype"
            placeholder="请选择"
            @change="labelchange($event, optionstype, 1)"
          >
            <el-option
              v-for="(item, index) in optionstype"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </li>
        <li>
          企业资质：
          <el-select
            class="iipp"
            v-model="valueaptitude"
            placeholder="请选择"
            @change="labelchange($event, optionsaptitude, 2)"
          >
            <el-option
              v-for="item in optionsaptitude"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </li>
        <li>
          注册资本：
          <el-select
            class="iipp"
            v-model="valuecapital"
            placeholder="请选择"
            @change="labelchange($event, optionscapital, 3)"
          >
            <el-option
              v-for="item in optionscapital"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </li>
        <li>
          上市状态：
          <el-select
            class="iipp"
            v-model="valuestate"
            placeholder="请选择"
            @change="labelchange($event, optionsstate, 4)"
          >
            <el-option
              v-for="item in optionsstate"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </li>
        <li>
          所在地区：
          <el-cascader
            :options="options"
            v-model="optionsvalue"
            clearable
             :placeholder="optionsareaplace"
            @expand-change="optionschange($event, 5)"
            @change="optionschange($event, 5)"
          ></el-cascader>
        </li>
        <li>
          成立时间：
          <el-select
            class="iipp"
            v-model="valuefinanc"
            placeholder="请选择"
            @change="labelchange($event, optionsfinanc, 6)"
          >
            <el-option
              v-for="(item, index) in optionsfinanc"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </li>
        <li>
          融资轮次：
          <el-select
            class="iipp"
            v-model="valuerounds"
            placeholder="请选择"
            @change="labelchange($event, optionsrounds, 7)"
          >
            <el-option
              v-for="(item, index) in optionsrounds"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </li>
        <span class="seach" @click="clicksearch360">查询</span>
      </ul>
      <ul class="zhanshi">
        <li class="lilast">
          <span v-if="type">
            {{ type }}
            <dl @click="(type = ''), (firmtype = '')">X</dl>
          </span>
          <span v-if="aptitude">
            {{ aptitude }}
            <dl @click="(aptitude = ''), (valueaptitude = '')">X</dl>
          </span>
          <span v-if="capital">
            {{ capital }}
            <dl @click="(capital = ''), (valuecapital = '')">X</dl>
          </span>
          <span v-if="state">
            {{ state }}
            <dl @click="(state = ''), (valuestate = '')">X</dl>
          </span>
          <span v-if="region">
            {{ region }}
            <dl @click="(region = ''), (valuerounds = '')">X</dl>
          </span>
          <span v-if="time">
            {{ time }}
            <dl @click="(time = ''), (valuefinanc = '')">X</dl>
          </span>
          <span v-if="optionsvalue2" style="min-width:168px;">
            {{ optionsvalue2 }}
            <dl @click="(optionsvalue = []), (optionsvalue2 = ''),(optionsareaplace='请选择')">X</dl>
          </span>

          <span v-if="financ">
            {{ financ }}
            <dl @click="(financ = ''), (valuerounds = '')">X</dl>
          </span>
        </li>
      </ul>

      <div class="tongj_echars" v-if="false">
        <div class="tongj_box">
          <h1>企业总数类型统计</h1>
          <div id="main0" style="width: 100%; height: 300px"></div>
        </div>

        <div class="tongj_box tongj_box2">
          <h1>企业总量资质统计</h1>
          <div id="main2" style="width: 100%; height: 300px"></div>
        </div>
      </div>

      <div class="tongj_list">
        <dl
          v-for="(item, index) in firmdata"
          :key="index"
          @click="goDetails(item.id)"
        >
          <dd>
            <h1 v-html="item.company"></h1>
            <p v-html="'所属行业：' + item.industry"></p>
          </dd>
          <dt>
            <span v-html="'主营产品：' + item.product"></span>
            <span>成立时间：{{ item.createtime }}</span>
            <span>注册资本：{{ item.money }}</span>
          </dt>
        </dl>
        <div
          class="jiaz_btns"
          @click="gosearch360"
        >
          {{ xiala }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  histogram360,
  field,
  getRegion,
  search360,
  seachhistory,
} from "@/api/index";
import echarts from "echarts/lib/echarts";
import "echarts/lib/component/graphic";
export default {
  name:"portrait",
  data() {
    return {
      xiala: "点击加载更多",
      tagnum: 5,
      optionsareaplace:"请选择",
      firmName: "",
      firmtype: "",
      firmtypenum: [],
      firmallnum: [],
      optionstype: [], //企业类型
      type: "",
      firmtype: "",
      aptitude: "",
      optionsaptitude: [], //企业资质
      valueaptitude: "",
      capital: "",
      optionscapital: [], //注册资本
      valuecapital: "",
      valuestate: "",
      state: "",
      optionsstate: [], //上市状态
      valuestate: "",
      options: [],
      optionsfinanc: [], //成立时间
      valuefinanc: "",
      time: "",
      optionsrounds: [], //融资轮次
      valuerounds: "",
      financ: "",
      region: "",
      optionsvalue: ["", "", ""],
      optionsvalue2: "",
      page: 1,
      perpage: "10",
      company: "",
      firmdata: [],
      loading: true,
      historySearch: "",
    };
  },
  mounted() {
    var that = this;
    window.onscroll = function () {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      console.log(scrollTop);
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      console.log(windowHeight);
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      console.log(scrollHeight);
      if (scrollTop + windowHeight <= scrollHeight) {
        //到了这个就可以进行业务逻辑加载后台数据了
        that.page++;
        // that.gosearch360();
        console.log("到了底部");
      }
    };
  },
  created() {
    this.gethistogram();
    this.getfield();
    this.getRegion();
    this.gosearch360();
  },
  methods: {
    async seachhistory() {
      const res = await seachhistory();
      this.historySearch = res.search;
    },
    // 柱状图数据
    async gethistogram() {
      const res = await histogram360();
      this.firmtypenum = res.type;
      this.firmallnum = res.quality;
      this.picture();
    },
    // 筛选数据
    async getfield() {
      const res = await field();
      this.optionstype = res.options.entype;
      this.optionsaptitude = res.options.qualificat;
      this.optionscapital = res.options.capital;
      this.optionsstate = res.options.quoted;
      this.optionstime = res.options.district;
      this.optionsfinanc = res.options.buildate;
      this.optionsrounds = res.options.financ;
    },
    // 获取省市区
    async getRegion() {
      const res = await getRegion();
      this.options = res;
    },
    aaabbb(e, data, num){
       console.log(1,e,data,num)
    },
    // optionsblur(e, data, num){
    //     console.log(2,e,data,num,"拾取")
    // },
    // 下拉框匹配数据
    optionschange(e, data, num) {
      console.log(1,7,78,88,8,8,)
      console.log(2,e,data,num)
      console.log(1)
      this.optionsvalue =e
       if (e[2] != undefined) {
        this.optionsvalue2 = e[0] + "/" + e[1] + "/" + e[2];
      } else if(e[1] != undefined){
        this.optionsvalue2 = e[0] + "/" + e[1]
        this.optionsareaplace= e[0] + "/" + e[1]

      }else if(e[0] != undefined){
        this.optionsvalue2 = e[0] 
        this.optionsareaplace= e[0] 

      } else {
        this.optionsvalue2 = "";
         this.optionsareaplace='请选择'
      }
      
      console.log(e,999)
      

      // if (e[0] != undefined) {
      //   this.optionsvalue2 = e[0] + "/" + e[1] + "/" + e[2];
      // } else {
      //   this.optionsvalue2 = "";
      // }
    },
    //搜索
    async gosearch360() {
      this.loading = true;
      if(this.optionsvalue.length !== 1){
        this.optionsvalue.push("")
      }if(this.optionsvalue.length !== 2){
        this.optionsvalue.push("")
        this.optionsvalue.push("")
      }
      var data = {
        qualificat: this.valueaptitude,
        entypeid: this.firmtype,
        area: this.optionsvalue,
        capital_id: this.valuecapital,
        public_id: this.valuestate,
        yearid: this.valuefinanc,
        company: this.company,
        page: this.page,
        per_page: this.perpage,
        financ_id: this.valuerounds,
      };
      const res = await search360(data);
      this.loading = false;
      if (res.data.length < 10) {
        this.xiala = "没有更多了";
      } else {
        this.xiala = "点击加载更多";
      }
      if (this.page == 1) {
        this.firmdata = res.data;
      } else {
        for (var i = 0; i < res.data.length; i++) {
          this.firmdata.push(res.data[i]);
        }
      }
      this.seachhistory();
      this.loading = false;
      // this.historySearch = res.search;
    },
    clicksearch360() {
      this.page = "1";
      this.gosearch360();
    },
    // 历史搜索
    clickhistory(val) {
      this.page = "1";
      this.company = val;
      this.gosearch360();
    },
    labelchange(e, obxj, num) {
      let obj = {};
      console.log(obxj);
      switch (num) {
        case 1:
          obj = obxj.find((item) => {
            console.log(e);
            return item.value === e; //筛选出匹配数据
          });
          console.log(obj);
          this.type = obj.label;
          break;
        case 2:
          obj = obxj.find((item) => {
            console.log(e);
            return item.value === e; //筛选出匹配数据
          });
          console.log(obj);
          this.aptitude = obj.label;
          break;
        case 3:
          obj = obxj.find((item) => {
            console.log(e);
            return item.value === e; //筛选出匹配数据
          });
          this.capital = obj.label;
          break;
        case 4:
          obj = obxj.find((item) => {
            console.log(e);
            return item.value === e; //筛选出匹配数据
          });
          this.state = obj.label;
          break;
        case 5:
          obj = obxj.find((item) => {
            console.log(e);
            return item.value === e; //筛选出匹配数据
          });
          this.region = obj.label;
          break;
        case 6:
          obj = obxj.find((item) => {
            console.log(e);
            return item.value === e; //筛选出匹配数据
          });
          this.time = obj.label;
          break;
        case 7:
          obj = obxj.find((item) => {
            console.log(e);
            return item.value === e; //筛选出匹配数据
          });
          this.financ = obj.label;
          break;
        // default:
        //   默认代码块;
      }
    },
    //新打开一个详情页
    goDetails(id) {
      console.log(id);
      let href = this.$router.resolve({
        // name: "chdetsils9",
        path: "/industrial/chdetsils9",
        query: {
          id: id,
          tagnum: 5,
        },
      });
      // window.open(href.href);
      window.location.href=href.href
    },

    picture() {
      // 用Dom获取上面已经创建的标签，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("main0"));
      // 指定图表的配置项和数据
      let option = {
        color: ["#999"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          top: "8%",
          left: "0",
          right: "0",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [
              "个人投资企业",
              "有限责任公司",
              "股份有限公司",
              "集体所有制",
              "合伙企业",
              "国有企业",
              "外商投资企业",
            ],
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0,
              textStyle: {
                color: "#666",
                //坐标的字体颜色
              },
            },
            axisLine: {
              lineStyle: {
                color: "#eee",
                //坐标轴的颜色
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            min: 0,
            // max: 7500,
            splitNumber: 11,
            // interval: 500, //每次增加几个
            splitLine: {
              lineStyle: {
                color: "#eee",
                //坐标轴的颜色
              },
              show: true,
              //去掉背景的网格线
            },
            axisLabel: {
              textStyle: {
                color: "#666",
                //坐标的字体颜色
              },
            },
            axisLine: {
              lineStyle: {
                color: "#eee",
                //坐标轴的颜色
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: "22",
            data: this.firmtypenum, //企业总数类型统计
            itemStyle: {
              normal: {
                barBorderRadius: 0,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#0285FF",
                  },
                  {
                    offset: 0.9,
                    color: "#0285FF",
                  },
                ]),
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表的。
      myChart.setOption(option);

      // 用Dom获取上面已经创建的标签，初始化echarts实例
      let myChart2 = this.$echarts.init(document.getElementById("main2"));
      // 指定图表的配置项和数据
      let option2 = {
        color: ["#999"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          top: "8%",
          left: "0",
          right: "0",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [
              "高新技术企业",
              "科技型中小企业",
              "上市企业",
              "独角兽企业",
              "瞪羚企业",
            ],
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0,
              textStyle: {
                color: "#666",
                //坐标的字体颜色
              },
            },
            axisLine: {
              lineStyle: {
                color: "#eee",
                //坐标轴的颜色
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            min: 0,
            // interval: 1000, //每次增加几个
            splitNumber: 11,
            splitLine: {
              lineStyle: {
                color: "#eee",
                //坐标轴的颜色
              },
              show: true,
              //去掉背景的网格线
            },
            axisLabel: {
              textStyle: {
                color: "#666",
                //坐标的字体颜色
              },
            },
            axisLine: {
              lineStyle: {
                color: "#eee",
                //坐标轴的颜色
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: "40",
            data: this.firmallnum, //企业总量资质统计
            itemStyle: {
              normal: {
                barBorderRadius: 0,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#40B2FF",
                  },
                  {
                    offset: 0.9,
                    color: "#40B2FF",
                  },
                ]),
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表的。
      myChart2.setOption(option2);
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  background: #f2f4f7;
  overflow: hidden;
  zoom: 1;
  font-family: "PingFangSC";
}
.portrait_ban {
  margin: 0 auto;
  background: url(../../assets/industrial/360qy_ban.png) no-repeat center;
  height: 225px;
  color: #fff;

  dl {
    padding: 65px;
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    zoom: 1;
    dt {
      height: 60px;
      line-height: 60px;
      width: 800px;
      margin: 0 auto;
    }
    /deep/.el-input {
      float: left;
      width: 650px;
      input {
        vertical-align: top;
      }
    }
    /deep/.el-input__inner {
      width: 650px;
      height: 50px;
      line-height: 50px;
      background: #dde7ff;
      border: 1px solid #dddddd;
      padding-left: 15px;
      border-radius: 0;
    }
    /deep/.el-button--primary {
      border-radius: 0;
      background: #fe6a00;
      height: 51px;
      line-height: 51px;
      color: #fff;
      border: 0;
      padding: 0;
      width: 150px;
      font-size: 16px;
      float: left;
    }
    ::-webkit-input-placeholder {
      color: #999;
    }
    dd {
      padding-top: 15px;
      font-size: 14px;
      color: #999;
      span {
        color: #fff;
        margin: 0 5px;
        cursor: pointer;
      }
    }
  }
}

.chuangx_fffbox {
  width: 1360px;
  overflow: hidden;
  zoom: 1;
  background: #fff;
  text-align: left;
  margin: 0 auto 20px auto;
  padding-bottom: 20px;

  .ipt {
    padding: 10px 0 0 35px;
    margin-bottom: 10px;
    overflow: hidden;
    zoom: 1;
    li {
      display: block;
      width: 24%;
      height: 31px;
      float: left;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      margin: 1% 1% 0 0;

      /deep/.el-select {
        width: 57%;
        height: 30px;
        input {
          width: 100%;
          height: 30px;
          background: #ffffff;
          border-radius: 0;
        }
        /deep/.el-input__icon {
          line-height: 30px !important;
        }
      }
      /deep/.el-input {
        width: 100%;
        height: 30px;
        input {
          width: 100%;
          height: 30px;
          background: #fff;
          color: #333;
          border: 1px solid #ddd;
        }
      }
      ::-webkit-input-placeholder {
        color: #333;
      }
      /deep/.el-input__inner {
        padding: 0 5px;
      }
    }
  }

  .zhanshi {
    height: 40px;
    display: flex;
    align-items: center;
    margin: 0% 2% 0;
    .lilast {
      list-style: none;
      span {
        display: block;
        // width: 168px;
        height: 31px;
        float: left;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 31px;
        border: 1px solid #dddddd;
        margin-right: 10px;
        padding: 0 5px;

        dl {
          float: right;
          color: #fe6a00;
          cursor: pointer;
        }
      }

      span:nth-child(2) {
        width: 182px;
      }
    }
  }

  .tongj_echars {
    overflow: hidden;
    zoom: 1;
  }
  .tongj_box {
    width: 45%;
    float: left;
    padding: 0 30px;
    h1 {
      font-size: 15px;
      text-align: left;
      font-weight: normal;
    }
  }
  .tongj_box2 {
    float: right;
  }
}
.seach {
  display: inline-block;
  width: 54px;
  height: 31px;
  background: #fe6a00;
  cursor: pointer;
  border-radius: 4px;
  line-height: 34px;
  font-size: 14px;
  margin-top: 1%;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
}

.tongj_list {
  margin: 30px;
  cursor:pointer;
}
.tongj_list dl {
  border: 1px solid #eee;
  margin-bottom: 20px;
}
.tongj_list dd {
  padding: 20px;
}
.tongj_list dd h1 {
  font-size: 18px;
  color: #fe6a00;
  font-weight: bold;
  margin-bottom: 8px;
}
.tongj_list dd p {
  font-size: 14px;
  color: #888;
}
.tongj_list dt {
  background: #f3f6fb;
  // height: 32px;
  line-height: 32px;
  display:flex;
  flex-wrap: wrap;
}
.tongj_list dt span {
  display: block;
  min-width: 130px;
  margin-left: 20px;
  font-size: 14px;
  float: left;
  color: #666;
}
.jiaz_btns {
  width: 130px;
  height: 37px;
  line-height: 37px;
  padding-right: 15px;
  font-size: 14px;
  text-align: center;
  background: #f2f4f7;
  border-radius: 19px;
  margin: 0 auto;
  color: #666;
  position: relative;
  cursor: pointer;
}
.jiaz_btns::before {
  content: "";
  width: 7px;
  height: 7px;
  border: solid #999;
  border-width: 0 0 1px 1px;
  transform: translate(-50%, -50%) rotate(-45deg);
  position: absolute;
  right: 15px;
  top: 17px;
}
/deep/.el-cascader {
  height: 30px;
  line-height: 30px;
}
/deep/.el-input__icon {
  height: 30px;
  line-height: 30px;
}
/deep/.el-loading-spinner i,
/deep/.el-loading-spinner .el-loading-text {
  color: #666 !important;
}
</style>